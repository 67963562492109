import styled from "@emotion/styled";
import { theme } from "../../styles";

export const ArticlePageContainer = styled.div`
  margin-top: 122px;
`;

export const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .Articles__LoadMore-button {
    width: 44px;
    height: 19px;
    cursor: pointer;
  }

  .Articles__LoadMore-label {
    text-transform: uppercase;
    margin-bottom: 10px;
    font-family: ${theme.fontFamily.secondary};
    font-size: ${theme.fonts.m_small};
    font-weight: bold;

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: ${theme.fonts.md};
    }
  }
`;
