import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { getQuery } from '../functions/getQuery';
import ProjectTiles from '../components/ProjectTiles/ProjectTiles';
import LocaleContext from '../context/LocaleProvider';
import Img from 'gatsby-image';
import Title from '../components/Title/Title';
import SEO from '../components/SEO';
import {
  ArticleMainContainer,
  ArticleHeroContainer,
  ArticleTemplateRelated,
  ArticleTemplateBodyImage,
} from '../components/ArticleTemplate/ArticleTemplate.css';
import Fade from 'react-reveal/Fade';

const ArticleTemplate = ({ data, pageContext: { locale, uid }, location }) => {
  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const query = data.individualArticles.edges.find(
    ({ node }) => node.uid === uid
  ).node.data;

  const relatedArticlessQuery = getQuery(['otherArticles', 'data'], data);

  const relatedArticles = data.individualArticles.edges.filter(({ node }) => {
    const matchingTags = [];

    if (node?.data?.tags?.length > 0 && node?.data?.tags?.text) {
      if (node.data.tags.text.includes(',')) {
        node.data.tags.text.split(',').forEach((tag) => {
          if (query?.tags?.text?.includes(tag)) {
            matchingTags.push(tag);
          }
        });
      } else {
        if (query?.tags?.text?.includes(node.data.tags.text)) {
          matchingTags.push(node.data.tags.text);
        }
      }

      return node.uid !== uid && matchingTags.length > 0;
    } else {
      return [];
    }
  });

  const dateArticle = new Date(query.date).toLocaleString(i18n.locale, {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  });

  const heroDesktopImage = query?.photo_horizontal?.url?.includes('gif') ? (
    <img
      className='ArticleTemplateHero__Img'
      src={query?.photo_horizontal?.url}
    />
  ) : (
    <Img
      fluid={query?.photo_horizontal?.heroDesktop?.childImageSharp?.fluid}
      imgStyle={{ objectFit: 'contain' }}
      className='ArticleTemplateHero__Img'
    />
  );

  return (
    <>
      <SEO location={location} pathname={location.pathname} locale={locale} />
      <ArticleMainContainer>
        <Fade bottom distance='30px'>
          <ArticleHeroContainer>{heroDesktopImage}</ArticleHeroContainer>
        </Fade>
        <Fade bottom distance='30px'>
          <div className='ArticleTemplate_Header'>
            <Title type='h4-secondary' customClassName='ArticleTemplate_Date'>
              {dateArticle}
            </Title>
          </div>
        </Fade>
        <Fade bottom distance='30px' delay={500}>
          <div>
            <Title type='h2_secondary' customClassName='ArticleTemplate_Title'>
              {query.title.text}
            </Title>
            <div
              className='ArticleTemplate_Body'
              dangerouslySetInnerHTML={{ __html: query?.body?.html }}
            />
            {query?.body_image?.localFile?.childImageSharp ? (
              <ArticleTemplateBodyImage
                fluid={query.body_image.localFile.childImageSharp.fluid}
              />
            ) : (
              ''
            )}
          </div>
        </Fade>
        {relatedArticles.length > 0 ? (
          <>
            <ArticleTemplateRelated>
              <Fade bottom distance='30px'>
                <Title
                  as='h2'
                  type='h3_secondary'
                  customClassName='ArticleTemplate_Title-secondary'
                >
                  {relatedArticlessQuery.other_articles.text}
                </Title>
              </Fade>
              <ProjectTiles
                id='ARTICLES'
                className='Articles__ProjectTiles'
                query={relatedArticles}
                numberOfVisibleArticles={
                  relatedArticlessQuery.number_of_articles_visible
                }
                numberOfArticlesToLoad={
                  relatedArticlessQuery.number_of_articles_to_load
                }
                loadMore={relatedArticlessQuery.load_more_button_label.text}
              />
            </ArticleTemplateRelated>
          </>
        ) : (
          ''
        )}
      </ArticleMainContainer>
    </>
  );
};

ArticleTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArticleTemplate;

export const query = graphql`
         query articleQuery($locale: String!) {
           otherArticles: prismicArticlesSection(lang: { eq: $locale }) {
             data {
               other_articles {
                 text
               }
               load_more_button_label {
                 text
               }
               number_of_articles_visible
               number_of_articles_to_load
             }
           }
           individualArticles: allPrismicArticleIndividual(
             filter: { lang: { eq: $locale } }
           ) {
             edges {
               node {
                 uid
                 id
                 lang
                 data {
                   top_priority
                   highlight_on_homepage
                   industry_selector_eng
                   industry_selector_fr
                   date(locale: "")
                   countries {
                     country
                   }
                   photo_horizontal {
                     url
                     heroDesktop: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                     desktop: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                     mobile: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   body {
                     html
                   }
                   body_image {
                     url
                     localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   excerpt {
                     html
                   }
                   photo_vertical {
                     url
                     localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   tags {
                     text
                   }
                   title {
                     text
                   }
                 }
               }
             }
           }
         }
       `;
