import React from 'react';
import PropTypes from 'prop-types';
import {
  ProjectTileContainer,
  ProjectTilesImage,
  ProjectTilesImageContainer,
} from './ProjectTiles.css';
import Title from '../Title/Title';
import Text from '../Text/Text';
import { Plus } from '../Icons/Plus';
import { Desktop, Mobile } from '../Media/Media';
import { truncate } from '../../functions/truncate';
import LocalizedLink from '../LocalizedLink';
import Fade from 'react-reveal/Fade';

const ProjectTile = (props) => {
  const { query, noLink = false } = props;

  return noLink ? (
    <div style={{ width: '100%' }}>
      <Tile {...props} />
    </div>
  ) : (
    <LocalizedLink style={{ width: '100%' }} to={`/${query.link}`}>
      <Tile {...props} />
    </LocalizedLink>
  );
};

const Tile = ({ query, id, customClassName, noPlusSymbol, index }) => {
  const tileProps = {
    className:
      customClassName === 'display-block'
        ? 'ProjectTile__Container display-block'
        : customClassName === 'display-none'
        ? 'ProjectTile__Container display-none'
        : 'ProjectTile__Container',
  };

  const imageDesktop = query?.photo?.url?.includes('.gif') ? (
    <img
      src={query?.photo?.url}
      style={{ width: '100%' }}
      className='ProjectTile__Photo Photo'
    />
  ) : (
    <ProjectTilesImage
      className='ProjectTile__Photo Photo'
      style={{ width: '100%' }}
      fluid={{
        ...query?.photo?.desktop?.childImageSharp?.fluid,
        aspectRatio: 350 / 218,
      }}
    />
  );

  const imageMobile = query?.photo?.url?.includes('.gif') ? (
    <img
      src={query?.photo?.url}
      style={{ width: '100%' }}
      className='ProjectTile__Photo Photo'
    />
  ) : (
    <ProjectTilesImage
      className='ProjectTile__Photo Photo'
      style={{ width: '100%' }}
      fluid={{
        ...query?.photo?.mobile?.childImageSharp?.fluid,
        aspectRatio: 350 / 218,
      }}
    />
  );

  return (
    <Fade delay={index * 30} distance='30px' bottom>
      <ProjectTileContainer {...tileProps}>
        <ProjectTilesImageContainer className='ProjectTilesImageContainer'>
          {query?.photo ? (
            <>
              <Desktop>{imageDesktop}</Desktop>
              <Mobile>{imageMobile}</Mobile>
            </>
          ) : query?.single_photo ? (
            <ProjectTilesImage
              className='ProjectTile__SinglePhoto Photo'
              fluid={{
                ...query.single_photo.localFile.childImageSharp.fluid,
                aspectRatio: 350 / 218,
              }}
            />
          ) : (
            ''
          )}
        </ProjectTilesImageContainer>
        {query?.month && query?.year && id ? (
          <div className='ProjectTile__Article-Date'>
            <Title customClassName='ProjectTile__Country-text'>
              {`${query.month} -`} {`${query.year}`}
            </Title>
          </div>
        ) : (
          <div
            className='ProjectTile_Border'
            style={{ padding: '0px', borderTop: '1px solid black' }}
          >
            <Title customClassName='ProjectTile__Country-text'>
              {query.country} {query.year ? `- ${query.year}` : ''}
            </Title>
          </div>
        )}

        {query?.title && id ? (
          <Title customClassName='ProjectTile__Title ProjectTile__Article-Title'>
            {truncate(query.title, 8)}
          </Title>
        ) : (
          <div className='ProjectTile_Border ProjectTile__Title-container'>
            <Title customClassName='ProjectTile__Title'>
              {truncate(query.title, 8)}
            </Title>
            {query?.excerpt ? (
              <Text
                type={'tertiary'}
                customStyle={{ marginTop: '15px', marginBottom: '0' }}
              >
                {truncate(query.excerpt, 20)}
              </Text>
            ) : (
              ''
            )}
          </div>
        )}
        {!id && query?.sections?.length > 0 ? (
          <div className='ProjectTile_Border'>
            {query.sections && query.sections.length > 0
              ? query.sections.map((section, index) => {
                  return (
                    <div
                      key={`ProjectTile__Sections-${index}`}
                      className='ProjectTile__Sections'
                    >
                      <Title
                        customStyle={{ marginBottom: '10px' }}
                        type='h4'
                        customClassName='ProjectTile__Body-Title'
                      >
                        {section.project_section_title.text}
                      </Title>
                      <Text
                        type={'secondary'}
                        customStyle={{ marginBottom: '15px' }}
                      >
                        {section.project_section_body.text}
                      </Text>
                    </div>
                  );
                })
              : ''}
          </div>
        ) : (
          ''
        )}
        {noPlusSymbol ? '' : <Plus className='ProjectTile__Plus PlusIcon' />}
      </ProjectTileContainer>
    </Fade>
  );
};

export default ProjectTile;

ProjectTile.propTypes = {
  query: PropTypes.shape({
    title: PropTypes.string.isRequired,
    photo: PropTypes.object,
    year: PropTypes.number,
    country: PropTypes.string,
    pdf: PropTypes.string,
    industry: PropTypes.string,
    excerpt: PropTypes.string,
    sections: PropTypes.array,
  }).isRequired,
};
