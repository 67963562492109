import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ProjectTilesContainer, ProjectTilesChild } from './ProjectTiles.css';
import ProjectTile from './ProjectTile';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LocaleContext from '../../context/LocaleProvider';
import { LoadMoreContainer } from '../PagesLayout/articles.css';
import { LoadMoreBtn } from '../Icons/LoadMoreBtn';
import Text from '../Text/Text';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    alignItems: 'self-start',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const ProjectTiles = ({
  query,
  id,
  className,
  numberOfVisibleArticles,
  numberOfArticlesToLoad,
  loadMore,
}) => {
  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];
  const classes = useStyles();

  const [showMore, setShowMore] = useState({
    state: false,
    visibleProducts: numberOfVisibleArticles,
  });

  const handleShowMore = () => {
    setShowMore((prevState) => ({
      ...prevState,
      visibleProducts: prevState.visibleProducts + numberOfArticlesToLoad,
      state: true,
    }));
  };

  return (
    <ProjectTilesContainer
      className={`${classes.root} ${className ? className : ''}`}
    >
      <Grid container className={`${classes.container}`} justify='flex-start'>
        {query.map(({ node: project }, index) => {
          let monthArticleTile;

          if (project?.data?.date) {
            monthArticleTile = new Date(
              project.data.date
            ).toLocaleString(i18n.locale, { month: 'short' });
          }

          const country = project?.data?.countries
            .map(({ country }) => country)
            .join(',');

          const tilesToDisplay =
            numberOfVisibleArticles && numberOfArticlesToLoad && loadMore
              ? showMore.visibleProducts
              : null;
          return (
            <ProjectTilesChild
              className={`${classes.grid} ProjectTilesContainer__GridChild ${
                index + (1 % 3) === 0 ? 'last-in-row' : ''
              }`}
              key={index}
            >
              <ProjectTile
                customClassName={
                  tilesToDisplay && tilesToDisplay > index
                    ? 'display-block'
                    : tilesToDisplay && tilesToDisplay <= index
                    ? 'display-none'
                    : ''
                }
                index={index}
                id={id ? id : ''}
                key={`project  - ${index}`}
                query={{
                  photo: project.data.photo_horizontal
                    ? { ...project.data.photo_horizontal }
                    : '',
                  title: project.data.title ? project.data.title.text : '',
                  year: project.data.year
                    ? project.data.year
                    : new Date(project.data.date).getFullYear(),
                  month: monthArticleTile ? monthArticleTile : '',
                  country,
                  pdf: project.data.download_project_pdf
                    ? project.data.download_project_pdf.url
                    : '',
                  industry: project.data.industry_selector_eng
                    ? project.data.industry_selector_eng
                    : project.data.industry_selector_fr,
                  excerpt: project.data.excerpt
                    ? project.data.excerpt.text
                    : '',
                  link: project.uid,

                  sections: project.data.project_sections
                    ? [
                        ...project.data.project_sections.filter(
                          (section) =>
                            section.include_section_on_homepage_project_tile
                        ),
                      ]
                    : null,
                }}
              />
            </ProjectTilesChild>
          );
        })}
      </Grid>
      {numberOfVisibleArticles &&
      numberOfArticlesToLoad &&
      loadMore &&
      showMore.visibleProducts < query.length ? (
        <LoadMoreContainer>
          <Text customClassName='Articles__LoadMore-label'>{loadMore}</Text>
          <div onClick={handleShowMore}>
            <LoadMoreBtn className='Articles__LoadMore-button' />
          </div>
        </LoadMoreContainer>
      ) : (
        ''
      )}
    </ProjectTilesContainer>
  );
};

export default ProjectTiles;

ProjectTiles.propTypes = {
  query: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string,
};
