import React from 'react';

export const Plus = ({ className }) => {
  return (
    <svg
      className={className ? className : ''}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 26.79 26.79'
    >
      <title>Read More Button</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <rect fill='#4357a6' x='12.9' width='2' height='26.79' />
          <rect fill='#4357a6' y='12.9' width='26.79' height='2' />
        </g>
      </g>
    </svg>
  );
};
