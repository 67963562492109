import React from 'react';

export const LoadMoreBtn = ({ className }) => {
  return (
    <svg
      className={className ? className : ''}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 44.37 16.74'
    >
      <title>Load More Button</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <polygon points='22.19 16.74 22.19 16.74 0 0 44.37 0 22.19 16.74' />
        </g>
      </g>
    </svg>
  );
};
